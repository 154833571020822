import React from "react";
import { List } from "@material-ui/core";
import PaymentMethodListButton from "../app/PaymentMethodListButton";
import { PaymentMethods, PreparationSteps } from "../../constants/invoice";
import iconCashV2 from '../../assets/img/icons/v2/cash.svg';
import iconFleetCheckV2 from '../../assets/img/icons/v2/fleet-check.svg';
import iconFleetFuelCardV2 from '../../assets/img/icons/v2/fleet-card.svg';
import iconCardV2 from '../../assets/img/icons/v2/credit-card.svg';
import iconRemoteCheckoutV2 from '../../assets/img/icons/v2/remote-checkout.svg';
import directBillIconV2 from '../../assets/img/icons/v2/direct-bill.svg';
import iconNo from '../../assets/img/icons/no.svg';
import { Invoice } from "../../types/Invoice";
import { FeatureFlag, FeatureFlagContainer } from "../ui/Visibility";
import { OnCompletedStepProps } from "../../types/OnCompletedStepProps";
import IfCanUseCreditCard from "../ui/visibility/IfCanUseCreditCard";
import { Company } from "../../types/Company";
import { CardAccountStatus } from "../../types/CardAccountStatus";
import { isReducePaperCheckFlowEnabled } from "../../services/app/company";

interface Props extends OnCompletedStepProps {
  isWexEnabled?: boolean;
  isRemoteCheckoutEnabled?: boolean;
  isCashEnabled?: boolean;
  isDirectBillEnabled?: boolean;
  isHostBillEnabled?: boolean;
  handleSelectPaymentMethod: (method: string) => void;
  invoice?: Invoice;
  company?: Partial<Company>;
  cardAccountStatus?: Partial<CardAccountStatus>;
  isComdataCardOn?: boolean;
  isFleetCheckOn?: boolean;
  comdataApiLocationId?: string;
}

// eslint-disable-next-line max-lines-per-function
const PaymentMethod: React.FC<Props> = (props: Props) => {
  const {
    isWexEnabled, isRemoteCheckoutEnabled, isCashEnabled, isDirectBillEnabled, isHostBillEnabled,
    onCompletedStep, handleSelectPaymentMethod, invoice, company, cardAccountStatus, isComdataCardOn, comdataApiLocationId, isFleetCheckOn
  } = props;

  const checkBtnLabel = isReducePaperCheckFlowEnabled(company) ? "Fleet Check" : PaymentMethods.CHECK.display;

  return (
    <List>
      {isFleetCheckOn &&
        <PaymentMethodListButton
          icon={<img src={iconFleetCheckV2} alt="iconFleetCheckV2" />}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.CHECK.key)}
          id={`select-payment-method-btn--${PaymentMethods.CHECK.key}`}
          text={checkBtnLabel}
        />
      }
      {isComdataCardOn && comdataApiLocationId ?
        <PaymentMethodListButton
          icon={<img src={iconFleetFuelCardV2} alt="iconFleetFuelCardV2" />}
          id={`select-payment-method-btn--${PaymentMethods.FUEL_CARD.key}`}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.COMDATA_CARD.key)}
          text={PaymentMethods.FUEL_CARD.display}
        />
        : (
          isWexEnabled &&
          <FeatureFlagContainer flag={FeatureFlag.FuelCards}>
            <PaymentMethodListButton
              icon={<img src={iconFleetFuelCardV2} alt="iconFleetFuelCardV2" />}
              id={`select-payment-method-btn--${PaymentMethods.FUEL_CARD.key}`}
              onClick={(): void => handleSelectPaymentMethod(PaymentMethods.FUEL_CARD.key)}
              text={PaymentMethods.FUEL_CARD.display}
            />
          </FeatureFlagContainer>
        )}
      <IfCanUseCreditCard company={company} status={cardAccountStatus}>
        <PaymentMethodListButton
          icon={<img src={iconCardV2} alt="iconCardV2" />}
          id={`select-payment-method-btn--${PaymentMethods.CARD.key}`}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.CARD.key)}
          text={PaymentMethods.CARD.display}
        />
      </IfCanUseCreditCard>
      {isRemoteCheckoutEnabled &&
        <PaymentMethodListButton
          icon={<img src={iconRemoteCheckoutV2} alt="iconRemoteCheckoutV2" />}
          text={PaymentMethods.REMOTE_CHECKOUT.display}
          id={`select-payment-method-btn--${PaymentMethods.REMOTE_CHECKOUT.key}`}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.REMOTE_CHECKOUT.key)}
        />
      }
      {isCashEnabled &&
        <PaymentMethodListButton
          icon={<img src={iconCashV2} alt="iconCashV2" />}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.CASH.key)}
          id={`select-payment-method-btn--${PaymentMethods.CASH.key}`}
          text={PaymentMethods.CASH.display}
        />
      }
      {isDirectBillEnabled &&
        <PaymentMethodListButton
          icon={<img src={directBillIconV2} alt="directBillIconV2" />}
          id={`select-payment-method-btn--${PaymentMethods.DIRECT_BILL.key}`}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.DIRECT_BILL.key)}
          text={PaymentMethods.DIRECT_BILL.display}
        />
      }
      {isHostBillEnabled &&
        <PaymentMethodListButton
          icon={<img src={iconCashV2} alt="iconCashV2" />}
          id={`select-payment-method-btn--${PaymentMethods.HOST_BILL.key}`}
          onClick={(): void => handleSelectPaymentMethod(PaymentMethods.HOST_BILL.key)}
          text={PaymentMethods.HOST_BILL.display}
        />
      }
      {!invoice?.type &&
        <PaymentMethodListButton
          icon={<img src={iconNo} alt="iconNo" />}
          id={`select-payment-method-btn--${PaymentMethods.UNKNOWN.key}`}
          onClick={(): void => onCompletedStep(PreparationSteps.LINE_ITEMS)}
          text={PaymentMethods.UNKNOWN.display}
        />
      }
    </List>
  );
}

export default React.memo(PaymentMethod);
