import { Invoice, InvoicePreparationStep } from '../../types/Invoice';
import { Location } from '../../types/Location';
import { PreparationSteps, InvoiceStatuses, PaymentMethods, InvoiceSource } from '../../constants/invoice';
import { WorkflowStatus } from '../../constants/workflowStatus';
import { isClientSupport, isCompanyAdmin, isRSEmployee, isRoadSyncEmployee, isSuperAdmin } from './auth';
import { getLocationsArrayFromSettings, getPreselectedLocationValuesIfOnlyOneOptionAvailable } from './location';
import { isPartialRefundEnabled, isPreValidatePaymentEnabled } from '../../services/app/company';
import { ProductType } from '../../constants/product';
import { forceValueToString, formatDollar } from './formats';
import { isAllowZeroDollarInvoiceEnabled } from './company';
import { LineItemPreview, LineItem } from '../../types/LineItems';
import { isEmail } from '../api/utils';
import { CheckTypes, CheckType } from "../../constants/deposit";
import { CustomField } from '../../types/CustomField';
import { AdminPaths, InvoicePaths } from './paths';
import { User } from '../../types/User';
import { Company } from '../../types/Company';
import { AppSettings } from '../../types/AppSettings';
import { LocationsState } from '../../reducers/locations';
import { ShiftsState } from '../../reducers/shifts';
import { DepartmentsState } from '../../reducers/departments';
import { Product } from '../../types/Product';
import { PriceBreakdown } from '../../types/PriceBreakdown';
import { Fee } from '../../types/fee';
import * as http from '../api/http';
import { Log } from '../LoggerService';
import { InvoicePriceBreakdown } from '../../types/InvoicePriceBreakdown';
import { LineItemsBreakdown } from '../../types/LineItemsBreakdown';
import { DepositStatus } from "../../components/deposits/DepositStatus";
import { AttachmentList } from '../../types/AttachmentList';
import { getInvoiceAttachmentByToken } from '../api/invoices';
import { InvoicePreparationSteps } from '../../thunks/InvoicePreparationSteps';
import { getAttributes, isTreatmentOn } from './split';
import { FeatureFlag } from '../../components/featureFlag/FeatureFlag';
import { Event } from '../../types';

// eslint-disable-next-line max-lines-per-function,max-statements
export function getPreparedInvoice(invoice: Invoice, products?: LineItem[], location?: Location): Invoice {
    invoice.lineItems = (products || invoice.lineItems || [])?.map((item) => {
        item.productId = item?.product?.id;
        item.qty = Number(item.qty);
        return item;
    });
    if (location) {
        invoice.departmentId = (invoice.departmentId || invoice.department) && location.departments?.length !== 0 ? String(invoice.departmentId || invoice.department) : undefined;
        invoice.locationId = String(location.id);
        invoice.shiftId = (invoice.shiftId || invoice.shift) && location.shifts?.length !== 0 ? String(invoice.shiftId || invoice.shift) : undefined;
    } else {
        invoice.departmentId = (invoice.departmentId || invoice.department) ? String(invoice.departmentId || invoice.department) : undefined;
        invoice.locationId = (invoice.locationId || invoice.location) ? String(invoice.locationId || invoice.location) : undefined;
        invoice.shiftId = (invoice.shiftId || invoice.shift) ? String(invoice.shiftId || invoice.shift) : undefined;
    }
    const initialEmail = invoice.payerEmail;
    const initialPhone = invoice.payerPhone;
    if (!initialEmail || !isEmail(initialEmail)) {
        invoice.payerEmail = initialPhone && isEmail(initialPhone) ? initialPhone : undefined;
    }
    if (!initialPhone || isEmail(initialPhone)) {
        invoice.payerPhone = initialEmail && isEmail(initialEmail) ? undefined : initialEmail;
    }

    return invoice;
}

function getNewStatusStep(invoice: Invoice, location?: Location): string {
    if (
        invoice?.lineItems?.length &&
        invoice.workflowStatus !== WorkflowStatus.SAVE_LINEITEMS_FOR_LATER
    ) {
        return invoice.type ? getDetailsStepByInvoiceType(invoice) : PreparationSteps.PAYMENT_METHOD;
    }
    return PreparationSteps.LOCATION_CUSTOM_FIELDS;
}

// eslint-disable-next-line
export function getStepForInvoice(
    invoice?: Invoice,
    location?: Location,
    locations?: LocationsState,
    settings?: AppSettings,
    me?: User,
    shifts?: ShiftsState,
    departments?: DepartmentsState,
    accessibleLocations?: string[],
    availableProducts?: Product[],
    isTowbookInvoice?: boolean
) {
    const canEditWithElevatedRole = isSuperAdmin(me) || isRSEmployee(me) || isClientSupport(me)
    if (invoice?.jumpToPage) {
        return invoice.jumpToPage;
    }

    if (!invoice) {
        if (!location || location?.products?.length) {
            if (isTowbookInvoice) {
                return PreparationSteps.IMPORT_TOWBOOK;
            }
            return PreparationSteps.PAYER_INFO;
        }
        return PreparationSteps.NO_PRODUCTS;
    }

    if (!accessibleLocations?.length && !canEditWithElevatedRole) {
        return PreparationSteps.NO_LOCATIONS;
    }

    if (location) {
        return availableProducts?.length ? PreparationSteps.LINE_ITEMS : PreparationSteps.NO_PRODUCTS;
    }

    if (settings && me && shifts && departments && locations && isCompanyAdmin(me)) {
        const locationData = getLocationsArrayFromSettings(settings, locations, me.companyId);
        if (locationData?.length === 1) {
            const preselectedOptions = getPreselectedLocationValuesIfOnlyOneOptionAvailable(
                settings, locations, shifts, departments, me.companyId
            );
            if (preselectedOptions.departmentId && preselectedOptions.shiftId && invoice?.id) {
                return PreparationSteps.LINE_ITEMS;
            }
            return PreparationSteps.PAYER_INFO;
        }
        if (locationData?.length > 1 && invoice?.id) {
            return PreparationSteps.PAYER_INFO;
        }
    }

    switch (invoice.status) {
        case InvoiceStatuses.WAITINGAPPROVAL.key:
        case InvoiceStatuses.PROCESSING.key:
        case InvoiceStatuses.COMPLETED.key:
            return PreparationSteps.NOT_ALLOWED_TO_EDIT;
        case InvoiceStatuses.FAILED.key:
            return getDetailsStepByInvoiceType(invoice);
        case InvoiceStatuses.NEW.key:
            return getNewStatusStep(invoice, location);
        default:
            return PreparationSteps.PAYER_INFO;
    }
}

export async function canFulfillInvoice(invoice: Invoice, company?: Company): Promise<boolean> {
    const validateCardEnabled = isPreValidatePaymentEnabled(company);
    return invoice.status === InvoiceStatuses.VALIDATED.key && validateCardEnabled;
}

export function getDetailsStepByInvoiceType(invoice: Invoice): PreparationSteps {
    switch (invoice.type) {
        case PaymentMethods.CASH.key:
        case PaymentMethods.HOST_BILL.key:
        case PaymentMethods.DIRECT_BILL.key:
            return PreparationSteps.CASH_DETAILS;
        case PaymentMethods.CARD.key:
            return PreparationSteps.CARD_DETAILS;
        case PaymentMethods.REMOTE_CHECKOUT.key:
            return PreparationSteps.SEND_FOR_REMOTE_CHECKOUT;
        case PaymentMethods.FUEL_CARD.key:
            let step;
            if (invoice.subtype === 'comdata') {
                step = PreparationSteps.NEW_FUEL_CARD_DETAILS
            } else {
                step = PreparationSteps.FUEL_CARD_DETAILS
            }
            return step;
        case PaymentMethods.DIRECT_PAYMENT.key:
            return PreparationSteps.DIRECT_PAYMENT;
        default:
            return PreparationSteps.CHECK_DETAILS;
    }
}

export function canEditInvoice(invoice?: Invoice): boolean {
    return !!invoice && !(
        (invoice.status === InvoiceStatuses.COMPLETED.key || invoice.status === InvoiceStatuses.PROCESSING.key || invoice.source === InvoiceSource.EXPRESS_PAYMENTS)
    );
}

export function canDeleteInvoice(invoice?: Invoice): boolean {
    return (
        invoice?.status === InvoiceStatuses.NEW.key ||
        invoice?.status === InvoiceStatuses.UNKNOWN.key ||
        invoice?.status === InvoiceStatuses.VALIDATED.key ||
        invoice?.status === InvoiceStatuses.VALIDATEDANDFAILED.key ||
        invoice?.status === InvoiceStatuses.SENT.key ||
        invoice?.status === InvoiceStatuses.FAILED.key
    ) && !invoice?.deletedAt;
}

export function getInvoiceEditUrl(invoice: Invoice, user?: User): string {
    return (
        invoice.status === InvoiceStatuses.COMPLETED.key || (user && isRoadSyncEmployee(user))
    )
        ? InvoicePaths.editApprovedInvoice(invoice.id)
        : InvoicePaths.editUrl(invoice.id);
}

export function getTowbookInvoiceEditUrl(invoice: Invoice, user?: User): string {
    return InvoicePaths.editTowbookUrl(invoice.id);
}

export function getCompanyAdminTowbookInvoiceEditUrl(companyId: string, invoice: Invoice): string {
    return AdminPaths.editTowbookInvoice(companyId, invoice.id);
}

export function getCompanyAdminInvoiceEditUrl(companyId: string, invoice: Invoice): string {
    return AdminPaths.editInvoice(companyId, invoice.id);
}

function isInvoiceTypeRefundable(invoice?: Partial<Invoice>): boolean {
    switch (invoice?.type) {
        case PaymentMethods.ACH.key:
        case PaymentMethods.CHECK.key:
        case PaymentMethods.FUEL_CARD.key:
            return false;
        default:
            return true;
    }
}

export async function canRefundInvoice(invoice: Invoice, company: Company | undefined): Promise<boolean> {

    const isComplete = invoice?.status === InvoiceStatuses.COMPLETED.key;
    const isDeleted = !!invoice?.deletedAt;
    const isCardInvoice = invoice?.type === PaymentMethods.CARD.key;
    const partialRefundsEnabled = await isPartialRefundEnabled(company);
    const isDepositIncomplete = invoice?.depositStatus === DepositStatus.Processing || !invoice?.depositStatus;
    if (!isComplete || isDeleted) {
        return false;
    }

    if (isCardInvoice && partialRefundsEnabled) {
        return true;
    }

    return isDepositIncomplete && isInvoiceTypeRefundable(invoice);
}



export function canDownloadReceipt(invoice?: Invoice): boolean {
    return invoice?.status === InvoiceStatuses.COMPLETED.key || !!invoice?.deletedAt;
}

export function canGoToPreviousStep(invoice: Invoice): boolean {
    return invoice?.status !== InvoiceStatuses.COMPLETED.key;
}

export function canSendInvoiceForCompletion(invoice: Invoice): boolean {
    return invoice && invoice.status !== InvoiceStatuses.COMPLETED.key && parseFloat(invoice.baseAmt) > 0;
}

export function getAllCustomFieldsForCompany(locations: LocationsState): CustomField[] {
    const fields = Object.keys(locations?.data || [])
        .map(id => locations?.data?.[id].customFields || [])
        .reduce((f, val) => f.concat(val), [])
        .filter(f => !!f.isShownInList);

    return fields.filter((v, i, a) => a.findIndex(f => (f.name === v.name)) === i);
}

export function getAllCustomFields(locations?: Location[]): CustomField[] {
    const res: { [k: string]: CustomField } = {};
    (locations || [])
        .map(l => l.customFields || [])
        .reduce((f, val) => f.concat(val), [])
        .filter(f => !!f.isShownInList)
        .forEach(f => res[f.name] = f);
    return Object.keys(res).map(k => res[k]);
}

export function round(value, decimals): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}

export async function calculateInvoiceTotal(invoice: Invoice, fees?: Fee[], newInvoiceTypeToCalculate?: string): Promise<PriceBreakdown> {
    const r = await calculateInvoiceTotalForAllPaymentMethods(invoice, fees);
    return r.invoiceTypes[newInvoiceTypeToCalculate || invoice.type || PaymentMethods.CASH.key];
}

export async function calculateInvoiceTotalForAllPaymentMethods(invoice: Invoice, fees?: Fee[]): Promise<InvoicePriceBreakdown> {
    try {
        return await http.postJSON<InvoicePriceBreakdown>(
            `/api/v1/public/invoices/${invoice.id}/calculate-totals`, {
            lineItems: (invoice.lineItems || []).map(i => {
                i.isTaxable = i.product ? i.product.isTaxable : i.isTaxable;
                return i;
            })
        }
        );
    } catch (e) {
        Log.captureException(e as any);
        throw e;
    }
}

export const getNonTaxLineItems = (items: LineItem[] = []): LineItem[] => {
    const { lineItemsWithoutTaxes } = getLineItemsBreakdownOffline(items);
    return lineItemsWithoutTaxes;
}

export const getTotalOffline = (items: LineItem[] = []): number => {
    return items
        .filter(product => product?.product && product.product.type !== ProductType.TAX.key)
        .map((item) => getItemCost(item))
        .reduce((a, b) => a + b, 0);
}

export function getItemCost(item: LineItem): number {
    switch (item?.product?.type) {
        case ProductType.CUSTOM.key:
        case ProductType.OTHER.key:
        case ProductType.ADD_MULTIPLE_LINE_ITEMS.key:
            return Number(item.qty);
        case "":
        case null:
        case undefined:
            return 0;
        case ProductType.ITEM.key:
        case ProductType.SERVICE.key:
        default:
            return Number(item.product.cost) * Number(item.qty || 0);
    }
}

// eslint-disable-next-line
export const isValidTotal = (invoice: Invoice, items: LineItem[], total: number | string = 0, company?: Company) =>
    (!!total && !!items?.length)
    || (isAllowZeroDollarInvoiceEnabled(company) && isZeroDollarAllowable(invoice));

export const isZeroDollarAllowable = (invoice: Invoice): boolean =>
    invoice.type === PaymentMethods.CASH.key
    || invoice.type === PaymentMethods.HOST_BILL.key
    || invoice.type === PaymentMethods.DIRECT_BILL.key;

export const getLineItemCostAndTitle = (item: LineItem): LineItemPreview => {
    const { product, qty, description } = item;
    return {
        cost: formatDollar(getItemCost(item)),
        description: description || product?.name || '',
        id: product?.id,
        showQty: product?.type === ProductType.ITEM.key || product?.type === ProductType.SERVICE.key ? qty.toString() : undefined,
    }
}

export const getInvoiceType = (value: string, row?: Invoice): string => {
    if (row && value) {

        const isRemoteCheckout = row?.initialType === PaymentMethods.REMOTE_CHECKOUT.key;
        if (value === PaymentMethods.CHECK.key && row.subtype && CheckTypes.getByKey(row.subtype)) {
            const val = `${PaymentMethods.getByKey(value).display} - ${CheckTypes.getByKey(row.subtype).display}`;
            return isRemoteCheckout
                ? `${PaymentMethods.getByKey(row.initialType).display} / ${val}`
                : val;
        }

        if (isRemoteCheckout && (value === PaymentMethods.CARD.key || value === PaymentMethods.ACH.key)) {
            return `${PaymentMethods.getByKey(row.initialType).display} / ${PaymentMethods.getByKey(value).display}`;
        }
    }

    return PaymentMethods.getByKey(value)?.display || value;
}

export function isInvoiceRefunded(invoice?: Invoice): boolean {
    return invoice?.status === InvoiceStatuses.COMPLETED.key
        && !!invoice?.deletedAt
        && !!invoice?.authorizedAt;
}

export function isInvoiceVoided(invoice?: Invoice): boolean {
    return !isInvoiceRefunded(invoice) && !!invoice?.deletedAt;
}

export function isInvoicePaid(invoice?: Invoice): boolean {
    return !isInvoiceRefunded(invoice) &&
        !isInvoiceVoided(invoice) &&
        invoice?.status === InvoiceStatuses.COMPLETED.key;
}

function getLineItemsBreakdownOffline(lineItems: LineItem[] = []): LineItemsBreakdown {

    const items = lineItems || [];
    const taxes = items.filter(item => item.product?.type === ProductType.TAX.key);
    const taxableLineItems = items
        .filter(item => item.product?.isTaxable || (item.product?.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key && item.isTaxable))
    const lineItemsWithoutTaxes = items.filter(item => !taxes.includes(item));
    const nonTaxableLineItems = items.filter(item => !taxes.includes(item) && !taxableLineItems.includes(item));
    const taxableProductsTotal = getTotalOffline(taxableLineItems);
    const subTotal = getTotalOffline(items);
    const totalTaxes = (
        taxes.map(item => item?.product?.cost || 0).reduce((sum, current) => Number(sum) + Number(current), 0) / 100
    ) * taxableProductsTotal;

    return {
        taxes, taxableLineItems, lineItemsWithoutTaxes,
        nonTaxableLineItems, subTotal, totalTaxes,
        grandTotal: subTotal + totalTaxes
    };
}

export function getPriceBreakdown(invoice?: Partial<Invoice>): PriceBreakdown {
    return {
        GrandTotal: parseFloat(invoice?.grandTotal || "0"),
        Subtotal: parseFloat(invoice?.subtotal || "0"),
        TaxTotal: parseFloat(invoice?.taxTotal || "0"),
        ConvenienceFee: parseFloat(invoice?.convenienceFee || "0"),
    };
}

export const FUEL_CARD_BIN_RANGES = ["501486", "530757", "556748", "589355", "199999", "600296", "6900460456", "700006", "707152", "708305", "560017", "556736", "556735"];
export const FUEL_CARD_LENGTHS = [16, 17, 18, 19];

export function isFuelCardNumber(number?: string | number): boolean {
    const input = forceValueToString(number);
    if (FUEL_CARD_BIN_RANGES.some(r => input.startsWith(r) && FUEL_CARD_LENGTHS.indexOf(input.length) > -1)) {
        return true;
    }
    return false;
}

export function getAttachmentListByToken(token: string, invoice: Invoice): AttachmentList {
    const attachmentURLs: AttachmentList = {};
    invoice.files?.forEach(f => attachmentURLs[f.fileId] = getInvoiceAttachmentByToken(token, f.fileId));
    return attachmentURLs;
}

export function sortProductsByWeight(productList: Product[]): Product[] {
    const products = [...productList];
    products.sort((a, b) => {
        const aWeight = "undefined" === typeof a.weight ? 1000 : a.weight;
        const bWeight = "undefined" === typeof b.weight ? 1000 : b.weight;
        return aWeight < bWeight ? -1 : aWeight > bWeight ? 1 : 0
    });
    return products;
}

export function triggerInvoiceUpdatedEvent(invoice: Invoice): void {
    const ev = new CustomEvent(Event.InvoiceUpdated, { detail: invoice });
    window.dispatchEvent(ev);
}

export function triggerCancelledFromHardwareEvent(): void {
    const ev = new CustomEvent(Event.CancelledFromHardware);
    window.dispatchEvent(ev);
}

export function triggerCancelledFromInovicePriceUpdatedEvent(): void {
    const ev = new CustomEvent(Event.InvoiceUpdatedDuringPayment);
    window.dispatchEvent(ev);
}

export const getExpressCodeLabel = (checkType?: CheckType): string =>
    checkType?.key === CheckTypes.USBANK.key
        ? 'Enter Card number or Transaction Code'
        : 'Enter Card Number or Express Code';

export const getAccountNumberLabel = (checkType?: CheckType): string =>
    checkType?.key === CheckTypes.USBANK.key
        ? 'Admin Code'
        : 'Account Number';

export function getInvoicePreparationStepByName(step: PreparationSteps): InvoicePreparationStep | undefined {
    return InvoicePreparationSteps.filter(s => s.stepName === step).shift();
}

export function isComdataV2On(invoice?: Invoice): boolean {
    return CheckTypes.COMCHEKV2.key === invoice?.subtype || CheckTypes.COMCHEK.key === invoice?.subtype;
}

export async function isFleetCheckServiceOn(me?: User, company?: Company): Promise<boolean> {
    const attributes = getAttributes(me, company);
    const isFleetChecksIsDisabled = await isTreatmentOn(FeatureFlag.FleetChecksIsDisabled, attributes)
    return !isFleetChecksIsDisabled;
}

export function isRemoteCheckout(invoice: Invoice): boolean {
    return invoice?.type === PaymentMethods.REMOTE_CHECKOUT.key;
}
